
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { SPHeaderCategoryList } from '@lib/common-react/components/header/SPHeaderCategoryList';
import React, { useEffect, useRef } from 'react';
import { css } from '@emotion/react';
import theme from '@styles/theme';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import PlayTypeProvider from '@/provider/PlayTypeProvider';
import { Story_Play_Type, Story_Sorting } from '@/baseType';

import { GetServerSideProps } from 'next';
import { SPHeader } from '@/lib/common-react/components/header/SPHeader';
import {
  STORY_PLAY_TAB_TYPES,
  StoryPlayTabType,
} from '@/lib/common-react/types/storyGame';
import { useRouter } from 'next/router';
import { touchSearchTab } from '@/lib/googleAnalytics';
import { getCurrentWeekType, isMobileDevice, isAndroid } from '@/common/utils';
import { useGetAllStoryGameTabsQuery } from '@/operations/queries/home/__generated__/useGetAllStoryGameTabs.generated';
import StoryGameMainHomeContainer from '@/components/storyGame/homeContainer/StoryGameMainHomeContainer';
import {
  goSearchForApp,
  goStoreForApp,
  goNotificationsForApp,
  onLogEventForApp,
} from '@/lib/webview';
import { logEventParams } from '@/customTypes/webview';
import { useGetTopHeaderTabs } from '@/hooks/useGetTopHeaderTabs';
import { viewSubTab, viewStorygameTab } from '@/lib/eventManager';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setPreviousStoryGameTabTitle } from '@/slices/eventSlice';
import useTranslation from 'next-translate/useTranslation';

const StoryGamePage = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { type, genre, tabId } = router.query;
  const { data: tabData } = useGetAllStoryGameTabsQuery();
  const { topHeaderTabs } = useGetTopHeaderTabs();
  const subTabEventParams = useRef<logEventParams | undefined>(undefined);

  useEffect(() => {
    if (isMobileDevice()) {
      isAndroid() && onLogEventForApp('view_storygame_tab');
    } else {
      viewStorygameTab();
    }

    window.requestSubTabEvent = requestSubTabEvent;

    return () => {
      delete window.requestSubTabEvent;
    };
  }, []);

  const requestSubTabEvent = () => {
    subTabEventParams.current &&
      onLogEventForApp('view_sub_tab', subTabEventParams.current);
  };

  useEffect(() => {
    const defaultTab = tabData?.getAllStorygameTabs[0];

    if (!type || !tabId) {
      router.push({
        pathname: router.pathname,
        query: {
          tabId: defaultTab?.storygameTabId,
          type: defaultTab?.type,
          ...(defaultTab?.genre && { genre: defaultTab?.genre }),
        },
      });
    }
  }, [type, router, tabData?.getAllStorygameTabs, tabId]);

  const categoryList = tabData?.getAllStorygameTabs.map((tab) => {
    return {
      id: tab.storygameTabId,
      title: t(tab.titleLokaliseKey),
      type: tab.type,
      genre: tab.genre,
    };
  });

  const handleTabClick = ({ type }: { type: StoryPlayTabType }) => {
    switch (type) {
      case STORY_PLAY_TAB_TYPES.interactive:
        router.push('/interactive');
        break;
      case STORY_PLAY_TAB_TYPES.storyGame:
        router.push('/home/storygame');
        break;
      case STORY_PLAY_TAB_TYPES.webNovel:
        router.push('/webnovel');
        break;
      case STORY_PLAY_TAB_TYPES.challenge:
        router.push(
          '/challenge?genre=TOTAL&sorting=RECOMMENDED&showFilter=true',
        );
        break;
      default:
        break;
    }
  };
  const dispatch = useAppDispatch();
  const previousStoryGameTabTitle = useAppSelector(
    (state) => state.event.previousStoryGameTabTitle,
  );

  useEffect(() => {
    if (!categoryList || !type) return;

    const currentTabIndex = categoryList.findIndex(
      (item) => item.type === type && (genre ? item.genre === genre : true),
    );
    const currentTabTitle =
      currentTabIndex >= 0 && categoryList?.[currentTabIndex]?.title;

    if (previousStoryGameTabTitle !== currentTabTitle) {
      if (isMobileDevice()) {
        const eventParams = {
          prev_tab: previousStoryGameTabTitle || '',
          tab: currentTabTitle || '',
          index: currentTabIndex + 1, // index는 1부터 시작
          main_tab: 'storygame',
        };
        onLogEventForApp('view_sub_tab', eventParams);
        subTabEventParams.current = eventParams;
      } else {
        viewSubTab({
          prevTab: previousStoryGameTabTitle || '',
          tab: currentTabTitle || '',
          index: currentTabIndex + 1, // index는 1부터 시작
        });
      }
    }
  }, [categoryList, type, genre]);

  const handleCategoryClick = ({
    tabId,
    type,
    genre,
    prevType,
    prevGenre,
  }: {
    tabId?: number;
    type: string; // Genre | Recommended | HOME | WEEK_DAY | ADULT
    genre?: string | null;
    prevType?: string;
    prevGenre?: string | null;
  }) => {
    if (router.pathname.includes('/storygame')) {
      const prevTabTitle = categoryList
        ?.filter((item) => item.type === prevType)
        .find((item) => item.genre === prevGenre)?.title;

      dispatch(setPreviousStoryGameTabTitle(prevTabTitle || ''));

      router.push({
        pathname: router.pathname,
        query: {
          tabId,
          type,
          ...(genre && { genre }),
        },
      });
      return;
    }

    router.push({
      pathname: router.pathname,
      query: {
        tab: type,
        ...(type === 'WEEK_DAY' && {
          week: getCurrentWeekType(),
          sorting: Story_Sorting.DailyRankAsc,
          genre: 'TOTAL',
        }),
      },
    });
  };

  const handleMainLogoIconClick = () => {
    router.push('/home/storygame');
  };

  const handleStoreIconClick = () => {
    isMobileDevice()
      ? goStoreForApp({ tabType: 'coin' })
      : router.push('/store');
  };

  const handleProfileIconClick = () => {
    router.push('/more');
  };

  const handleSearchIconClick = () => {
    isMobileDevice() ? goSearchForApp() : router.push('/search');
    touchSearchTab();
  };

  const handleNotificationIconClick = () => {
    isMobileDevice() ? goNotificationsForApp() : router.push('/notification');
  };

  return (
    <PlayTypeProvider playType={Story_Play_Type.Storygame}>
      <div css={container}>
        <SPHeader
          data={{
            tabList: topHeaderTabs,
            currentTabType: STORY_PLAY_TAB_TYPES.storyGame,
          }}
          actions={{
            onLogoClick: handleMainLogoIconClick,
            onTabClick: handleTabClick,
            onStoreIconClick: handleStoreIconClick,
            onSearchIconClick: handleSearchIconClick,
            onProfileIconClick: handleProfileIconClick,
            onNotificationIconClick: handleNotificationIconClick,
          }}
          isDarkTheme
          maxWidth={`calc(${theme.maxWidthV2} + 3rem)`}
          tab="story-game"
        />
        <div className="story-game-contents-wrap">
          <div className="story-game-contents">
            <SPHeaderCategoryList
              data={{
                categoryList,
                currentCategoryType:
                  (type as string) || categoryList?.[0].type || '',
                currentCategoryGenre:
                  (genre as string) || categoryList?.[0].genre,
              }}
              actions={{
                onCategoryClick: handleCategoryClick,
              }}
              isDarkTheme
            />
            <main className="main-container">
              {tabId && (
                <StoryGameMainHomeContainer tabId={Number(tabId as string)} />
              )}
            </main>
          </div>
        </div>
      </div>
    </PlayTypeProvider>
  );
};

const container = css`
  width: 100%;
  max-width: calc(${theme.maxWidthV2} + 3rem);
  display: flex;
  align-items: center;
  flex-direction: column;

  .story-game-contents-wrap {
    width: 100vw;
    container-name: story-game-home-tab-container;
    container-type: inline-size;
    min-height: 4.125rem;

    .story-game-contents {
      width: 100%;
      max-width: calc(${theme.maxWidthV2} + 3rem);
      margin-left: auto;
      margin-right: auto;

      .main-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-top: -1.5rem;
        min-height: calc(100vh - 6.75rem);
      }
    }
  }
`;

 const _getServerSideProps: GetServerSideProps = async () => {
  return {
    props: {
      playType: Story_Play_Type.Storygame,
    },
  };
};

export default StoryGamePage;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        let res = _getServerSideProps(ctx)
// @ts-ignore
        if(typeof res.then === 'function') res = await res
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          ...res,
// @ts-ignore
          props: {
// @ts-ignore
            ...(res.props || {}),
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/home/storygame',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  